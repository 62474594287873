import React from "react";
import {
  // Customizable Area Start
  Container,
  Box,
  Input,
  Typography,
  Grid,
  Button,
  TextareaAutosize, 
  Paper,
  TextField,
  InputAdornment,
  DialogContent,
  Dialog,
  Tooltip,
  DialogActions,
  DialogContentText,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import PhoneInput from "react-phone-input-2";
import CheckIcon from '@material-ui/icons/Check';
import { View, StyleSheet, Text,Image} from "react-native";
import {
    imgLogo,
    createImage,
    imgUser,
    imgNotification,group_plus
  } from "./assets";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Formik } from "formik";
import * as Yup from "yup";


const CustomFormWrapper = styled("div")({
  position: "relative",
  minHeight: 46,
  display: "block",
  '& .react-tel-input .form-control:focus':{
    boxShadow:"none !important",
  }
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const configJSON = require("./config");
import HelpCenterController, {
  Props,
} from "./HelpCenterController";
import  AppHeader  from "../../../components/src/AppHeader.web";
import GenericLabel from "../../../components/src/GenericLabel";
import { imgClose } from "../../categoriessubcategories/src/assets";
import { loadingImg } from "../../customform/src/assets";
// Customizable Area End

export default class HelpCenter extends HelpCenterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }


  render() {
    // Customizable Area Start
    const {selectedFile,getFAQRes,searchInputRes,searchStatus,isLoading} = this.state

    if (isLoading) {
      return   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{height:"50%",width:"25%"}}/>
  </div>
              }
    const queryBlock = (item:any , index :any) =>{
      return (
        <Paper key ={index} style={{ border: '3px solid black', paddingTop: 5, borderRadius :0 }}>
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 16, paddingLeft: 24, paddingRight: 40, paddingBottom: 16 }}>
          <GenericLabel color="#000" fontFamily='CircularStdBold' fontSize={16} mb={10}>{item.attributes.question}</GenericLabel>
          <GenericLabel color="#5A5A5A" fontFamily='CircularStd' fontSize={14} lineHeight={19} pr={15}>{item.attributes.answer}</GenericLabel>
        </div>
      </Paper>
      )
    }

    const fileNameFn2 = () =>{
      return (
        selectedFile.name.length > 20 ? `${selectedFile.name.substring(0, 20)}...` : selectedFile.name
      )
    }

    const fileNameFn=()=>{ return (selectedFile ? fileNameFn2() : configJSON.labelAttachBriefCustomContent)}
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          maxWidth={false}>
          <AppHeader
            logoUrl={imgLogo}
            homeText="Home"
            createImageUrl={createImage}
            helpCenterText="Help Centre"
            userUrl={imgUser}
            settingsIcon
            helpCenterNotificationUrl={imgNotification}
            onPressHome={() => this.goToHomeHelpCenter()}
            onClickViewProfile={() => this.goToProfileHelpCenter()}
            onClickHelpCenter={() => this.goToHelpCenter()}
            quoteUrl={group_plus}
            onClickViewEnquiries={() => this.goToEnquiryHelpCenter()}
            onClickSettings={() => this.goToSettingsHelpCenter()}
            onClickQuote={() => this.goToQuoteHelpCenter()}
            data-test-id="testAppHeader"
          />
          <View>
            <HelpCenterContainer style={{
              minHeight: "calc(100vh - 76px)", display: "flex", flexDirection: "row"
            }}>
              <div className="leftSide" style={{ borderRight: '3px solid black', backgroundColor: "white", width: "30%", display:"flex" }}>
                <Container className="leftSideWrapper" disableGutters maxWidth={false}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}   onClick={() => this.goBackHelpCenter()} 
        data-test-id="testBackButton">
                    <ArrowBackIcon style={{ color: '#5A5A5A' }} />
                    <span 
        style={{ color: '#5A5A5A',fontFamily: "CircularStd, sans-serif",backgroundColor:"transparent",fontSize:"14px",fontWeight:500, textTransform:"none",minWidth:"50px" ,textAlign:"center"}}
       >
        Back
      </span>

                  </div>
                  <GenericLabel fontSize={30} lineHeight={40} fontFamily='CircularStdBold' color="#000">Help Centre</GenericLabel>
                  <InputTextField
                    variant="outlined"
                    margin="dense"
                    placeholder="Write a question or problem"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: { border: '3px solid black', width: '100%', fontFamily: 'CircularStd', fontSize: 14, borderRadius: 0, marginTop:20 },
                    }}
                    onChange={this.handleInputChange}
                    data-test-id="testSearchInput"
                  />

                  <Paper style={{
                    backgroundColor: '#DEDCFD', border: '3px solid black', width: '100%', borderRadius: 0, marginTop:'auto', marginBottom: 50
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 16, paddingLeft: 'min(20px,2.5vw)', paddingRight: 'min(20px,2.5vw)', paddingBottom: 16 }}>
                      <GenericLabel color="#000" fontFamily='CircularStdBold' fontSize={16} mb={10}>Do you still need help?</GenericLabel>
                      <Text style={{ color:"#000", fontFamily:'CircularStd', maxWidth:270, fontSize:14, lineHeight:19}}>Send us an email and our team will get back to you.</Text>
                      <div style={{
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop:5,
                        maxWidth:'100%'
                      }}>
                        <div className="buttonWrapper btn-blue" style={{ left: 2, top: 2, width: 138.6, maxWidth:'100%' }} >
                          <div className="buttonOutline" ></div>
                          <Button className="btn-sm" style={{ width: 138, maxWidth: '100%' }} onClick={this.displayModel}>Contact Us</Button>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Container>
              </div>
              <div className="rightSide" style={{
                height: '100%', width: '100%',
                backgroundColor: '#F7F6FF', overflow: 'auto',
              }}>
                <div >
                  <div className="forMobile">
                    <GenericLabel fontSize={20} lineHeight={40} fontFamily='CircularStdBold' color="#000">Help Centre</GenericLabel>
                    <InputTextField
                        variant="outlined" margin="dense"
                        placeholder="Write a question or problem" fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"><SearchIcon /></InputAdornment>
                          ),
                          style: {  width: '100%', borderRadius: 0, border: '3px solid black', paddingLeft:10, fontFamily: 'CircularStd', fontSize: 14, marginTop:5, backgroundColor:'#FFFFFF' },
                        }}
                        data-test-id="testSearchInput1"
                        onChange={this.handleInputChange}
                      />
                  </div>
                  <div className="forDesktop">
                    <GenericLabel fontSize={30} lineHeight={44} fontFamily='CircularStdBold' color="#000">FAQ</GenericLabel>
                  </div>
                  <Box className="rightSideBox">
                    {searchStatus ?
                      searchInputRes.map((item: any, index: any) => { return (queryBlock(item, index)) })
                      :
                      getFAQRes.map((item: any, index: any) => { return (queryBlock(item, index)) })
                    }
                    {searchStatus && searchInputRes.length === 0 &&
                      <Paper style={{ border: '3px solid black', paddingTop: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 16, paddingLeft: 24, paddingRight: 40, paddingBottom: 16 }}>
                          <GenericLabel color="#000" fontFamily='CircularStdBold' fontSize={16} mb={10}>No matching results. Try different keywords or check for spelling.</GenericLabel>
                        </div>
                      </Paper>
                    }
                  </Box>
                  <Paper className="forMobile" style={{
                    backgroundColor: '#DEDCFD', border: '3px solid black', marginTop:'30px',marginBottom:'10px', borderRadius: 0,
                  }}>
                    <div style={{ flexDirection: 'column', display: 'flex', padding:"16px 40px 16px 24px" }}>
                      <GenericLabel color="#000" mb={10} fontFamily='CircularStdBold' fontSize={16}>Do you still need help?</GenericLabel>
                      <Text style={{ color:"#000", maxWidth:270, lineHeight:19, fontSize:14, fontFamily:'CircularStd',}}>Send us an email and our team will get back to you.</Text>
                      <div style={{
                        position: 'relative', width: '100%',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        marginTop:5, maxWidth:'100%'
                      }}>
                        <div className="buttonWrapper btn-blue" style={{ left: 2, maxWidth:'100%', width: 138.6, top: 2, }} >
                          <div className="buttonOutline" ></div>
                          <Button className="btn-sm" style={{ maxWidth: '100%', width: 138, }} onClick={this.displayModel}>Contact Us</Button>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </div>
              </div>
            </HelpCenterContainer>
          </View>
          {this.state.submissionStatus === false ? (
            <Dialog id="model_add" maxWidth="md" open={this.state.showModel} onClose={this.hideModal} style={{ border: '3px solid black' }} scroll="body">
              <ContactDialogContent style={{ alignItems: 'center', width: 700, border: '3px solid black', maxWidth:'100%', boxSizing:'border-box' }}>
                <div data-testid="hideModal" onClick={this.hideModal} style={{ position: "absolute", right: 20, top: 20, cursor: "pointer", }}>
                  <Image
                    source={imgClose}
                    resizeMode="cover"
                    style={{ height: 18, width: 18, }}
                  />
                </div>
                <Box className="contactBox">
                  <Typography
                    variant="h4"
                    component="h2"
                    style={{
                      fontSize: 30,
                      color: "#000",
                      justifyContent: "center",
                      marginBottom: "35px",
                      display: "flex",
                      fontFamily: 'CircularStd',
                      fontWeight: 600,
                      backgroundColor: "#fff"
                    }}
                  >
                    Contact Us
                  </Typography>
                  <Formik
                    initialValues={{
                      first_name: this.state.userProfileDatafirstName,
                      last_name: this.state.userProfileDatalastname,
                      full_phone_number: this.state.userProfileDataMobile,
                      company_name: "",
                      details: "",
                      subject: ""
                    }}
                    validationSchema={Yup.object().shape(this.state.contactFormSchema)}
                    validateOnMount={true}
                    validateOnChange={true}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                      this.submitContactDetails(values);
                      actions.setSubmitting(false);
                    }}
                    data-testid="contactForm"
                  >
                    {({ setFieldValue, values, handleSubmit, touched, errors }) => (
                      <form onSubmit={handleSubmit} className="mt-5 mb-2">
                        {((touched.first_name && errors.first_name) ||
                          (touched.last_name && errors.last_name) ||
                          (touched.subject && errors.subject) ||
                          (touched.details && errors.details) ||
                          (touched.full_phone_number && errors.full_phone_number)) && (
                            <AlertBox data-testid="alertMessage">
                              <div>
                                {errors.first_name && <div>{errors.first_name}</div>}
                                {errors.last_name && <div>{errors.last_name}</div>}
                                {errors.subject && <div>{errors.subject}</div>}
                                {errors.details && <div>{errors.details}</div>}
                                {errors.full_phone_number && <div>{errors.full_phone_number}</div>}
                              </div>
                            </AlertBox>
                          )}
                        <Grid className="nameFields">
                          <Grid className="fieldWrapper" item xs={6}>
                            <Text style={{
                              fontSize: 16,
                              marginLeft: 2,
                              color: "#000",
                              width: 88,
                              marginBottom: 5,
                              display: "flex",
                              fontFamily: 'CircularStdBold',
                            }}>First Name*
                            </Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="txtFname"
                                placeholder={"First Name"}
                                name="first_name"
                                value={values.first_name}
                                style={{ width: '100%', borderRadius:0 }}
                                onChange={(event) => {
                                  setFieldValue('first_name', event.target.value);
                                  this.resetErrorMessage();
                                }}
                                disableUnderline={true}
                              />
                            </CustomFormWrapper>
                          </Grid>
                          <Grid className="fieldWrapper" item xs={6}>
                            <Text style={{
                              fontSize: 16,
                              color: "#000",
                              width: 88,
                              marginBottom: 5,
                              display: "flex",
                              fontFamily: 'CircularStdBold',
                            }}>Last Name*</Text>
                            <CustomFormWrapper>
                              <CustomInputStyle
                                data-test-id="txtLname"
                                placeholder={"Last Name"}
                                name="last_name"
                                value={values.last_name}
                                style={{ width: '100%' }}
                                onChange={(event) => {
                                  setFieldValue('last_name', event.target.value);
                                  this.resetErrorMessage();
                                }}
                                disableUnderline={true}
                              />
                            </CustomFormWrapper>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Email ID*</Text>
                          <CustomFormWrapper>
                            <CustomInputStyle
                              data-test-id="email-id"
                              placeholder={"email@email.com"}
                              disabled
                              fullWidth={true}
                              name="email"
                              value={this.state.userProfileDataemail}
                              style={{
                                color: "#000"
                              }}
                              onChange={(event) => {
                                setFieldValue('email', event.target.value);
                                this.resetErrorMessage();
                              }}
                              disableUnderline={true}
                            />
                          </CustomFormWrapper>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Mobile Number*</Text>
                          <CustomFormWrapper>
                            <PhoneInput
                              data-test-id="phone-number"
                              country={'us'}
                              value={values.full_phone_number}
                              placeholder="Phone Number"
                              buttonStyle={{
                                borderTop: "3px solid black",
                                borderBottom: "3px solid black",
                                color: "#000", textAlign: "center", borderRadius: 0, borderRight: 0, backgroundColor: "#fff", fontFamily: "CircularStd", marginLeft: "3px"
                              }}
                              onChange={(event) => {
                                setFieldValue('full_phone_number', event);
                                this.resetErrorMessage();
                              }}
                              inputStyle={{
                                border: "3px solid #000", height: '44px', borderRadius: 0, paddingBottom: "10px", paddingTop: "10px", width: '100%'
                              }}
                            />
                          </CustomFormWrapper>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Company Name*</Text>
                          <CustomFormWrapper>
                            <CustomInputStyle
                              data-test-id="companyName"
                              placeholder={"Company"}
                              fullWidth={true}
                              disabled
                              name="subject"
                              value={this.state.userProfileDataCompany}
                              style={{ color: "#000" }}
                            />
                          </CustomFormWrapper>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Subject*</Text>
                          <CustomFormWrapper>
                            <CustomInputStyle
                              data-test-id="subject"
                              placeholder={"Type Here"}
                              fullWidth={true}
                              name="subject"
                              value={values.subject}
                              onChange={(event) => {
                                setFieldValue('subject', event.target.value);
                              }}
                              disableUnderline={true}
                            />
                          </CustomFormWrapper>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20 }}>
                          <Text style={styles.customLabel}>Tell us how we can help in a few words*</Text>
                          <CustomFormWrapper>
                            <CustomTextInputStyle
                              minRows={1}
                              data-test-id="txtInputHelpDescription"
                              placeholder="Type Here"
                              name="details"
                              value={values.details}
                              maxLength={1000}
                              style={{
                                paddingTop: 10,
                                resize: "vertical",
                                paddingBottom: 10,
                                marginBottom: 5,
                                borderRadius: 0
                              }}
                              onChange={(event) => {
                                setFieldValue('details', event.target.value);
                                this.setTextcounter(event.target.value.length);
                                this.resetErrorMessage();
                              }}
                            />
                            <Box style={{
                              marginRight: 10,
                              fontSize: 12,
                              fontFamily: 'CircularStd', position: "absolute", right: 10,
                              zIndex: 2, color: '#ADB3BC', marginTop: "-21px",
                              opacity: 1.3,
                            }}>{this.state.textcounter}/1000</Box>
                          </CustomFormWrapper>
                        </Grid>

                        <Grid className="nameFields" style={{ marginTop: 20, alignItems: "center" }}>
                          <Grid item xs={6}>
                            <Text style={styles.customLabel}>Attach brief</Text>
                            <CustomFormWrapper>
                              <CustomButtonOutline />

                              <SubmitButton
                                data-testid="chooseFile"
                                onClick={this.openFileInput}
                              >
                                <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={this.handleFileChange}
                                  ref={this.fileInput}
                                  data-test-id="testFileInput"
                                />
                                Choose file
                              </SubmitButton>
                            </CustomFormWrapper>
                          </Grid>
                          <Grid item xs={6} style={{ paddingTop: 30 }}>
                            <div style={{
                              fontSize: 16,
                              color: "#000",
                              marginBottom: 5,
                              display: "block",
                              fontFamily: 'CircularStdBold',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}>
                              <Tooltip title={this.state.contactFilename} placement="top">
                                <FileName>{fileNameFn()}</FileName>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid className="contact-submit" item xs={12}>
                          <CustomFormWrapper>
                            <CustomButtonOutline />
                            <SubmitButton
                              data-testid={"submitBtn"}
                              type="submit"
                            >
                              Submit
                            </SubmitButton>
                          </CustomFormWrapper>
                        </Grid>
                      </form>
                    )}
                  </Formik>

                </Box>

              </ContactDialogContent>
            </Dialog>) : (
            <Dialog id="model_add" maxWidth="md" 
            open={this.state.showModel } 
            onClick={this.hideModal}  
            style={{border:'3px solid black'}} scroll="body">
              <DialogContent style={{ width:'480px', height:'448px', maxWidth:'100%', display: 'flex', alignItems:'center', border:'3px solid black', boxSizing:"border-box", flexDirection: 'column', paddingTop: '100px'}}> 
                <div data-testid="hideModal" onClick={this.hideModal}  style={{right:'16px', position:"absolute", cursor:"pointer", top:'16px',}}>
                  <Image source={ imgClose} resizeMode="cover" style={{ height:'20px', width:'20px',}}/>
                </div>
                <Box style={{display: 'flex', justifyContent: 'center',gap: '25px', flexDirection: 'column', alignItems: 'center'}}>
                    <Box style={{border: 'solid 3px #000',width:'fit-content',backgroundColor: '#00BFBA', padding: '10px',}}>
                      <CheckIcon style={{ fontSize: '48px',width:'60px', height:'60px', color: 'black'}} />
                    </Box>
                    <Typography component="h2" variant="h5" style={{ fontSize: 30, marginBottom: "0", fontFamily:'CircularStdBold',lineHeight: '44px', color: "#000", backgroundColor: 'transparent', textAlign:'center'}}>Successfully Submitted</Typography>
                </Box>
              </DialogContent>
            </Dialog>
          )}
          <Dialog
            open={this.state.isSuspended}
            onClose={this.handleGoToLoginPage}
            aria-labelledby="suspended-account-dialoge"
            aria-describedby="suspended-account-dialoge-description"
            data-test-id="suspendedModal"
          >
            <Typography style={accountSuspendedModal.suspendedTitle}>
              {configJSON.supsendedModalTitle}
            </Typography>
            <DialogContent>
              <DialogContentText id="suspended-account-dialoge-description">
                {this.state.suspendedMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={accountSuspendedModal.btnWrapper}>
              <Button style={accountSuspendedModal.okBtn} variant="contained" disableElevation onClick={this.handleGoToLoginPage}>
                {configJSON.supspendedModalOkBtn}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const accountSuspendedModal = {
  okBtn:{
    backgroundColor:"#fff",
    textTransform:"capitalize" as "capitalize",
    color:"black",
    borderRadius:"inherit",
    border: "2px solid #000",
    boxShadow: "#584EF5 4px 4px 0 0,#000 5px 5px 0 2px",
    boxSizing: "border-box" as "border-box",
    cursor: "pointer",
    display: "inline-block",
    fontSize: "14px",
    fontWeight:600,
    paddingBlock:"3px",
  },
  suspendedTitle:{
    fontSize:20,
    color:"black",
    padding:10,
    paddingLeft:24
  },
  btnWrapper:{
    paddingRight:17,
    paddingBottom:16,
  },
}

const FileName = styled(`div`)({
  maxWidth: "200px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
})

const CustomButtonOutline = styled("div")({
  bottom: '2px',
  width: "98%",backgroundColor: "#594FF5",zIndex: 0,
  border: "solid 3px #000",height: 33,
  position: "absolute",
  right: "-5px",
});
const AlertBox = styled("div")({
  border:0,
  borderLeft:4,
  boxSizing:'border-box',
  borderTopLeftRadius:5,
  borderBottomLeftRadius:5,fontFamily: 'CircularStd', padding:20,backgroundColor: "#FEE2E2",borderLeftColor:"#FF5B74",borderStyle:"solid",marginBottom:30,color:"#FF5B74",width:'100%',
});

const SubmitButton = styled(Button)({fontSize: "14px", width: "100%",
  padding: "4px",
  backgroundColor: "#fff",
  color: "#000",
  border: "solid 3px #000", borderRadius: 0,textTransform: "none",fontFamily:'CircularStdBold', 
  "&:focus": {
    backgroundColor: "#DEDCFD",
  },"&:hover": { backgroundColor: "#BDB9FB",},
 
});


const styles = StyleSheet.create({
  customLabel: {fontSize: 16,color: "#000",marginBottom: 5,display: "flex",fontFamily: 'CircularStdBold',
  },
  staticBottomContent: {
    maxWidth:'596px',alignItems: "center",padding: "0 20px",marginLeft: "auto",marginRight:"auto",
  }
});

const CustomInputStyle = styled(Input)({
  "& .MuiInputBase-input": {backgroundColor: "#fff",
    height: "22px",
    position: "relative",
    zIndex: 1,borderColor: "#000",
    width: "100%",
    paddingLeft: "5px",paddingRight: "5px",
    borderStyle: "solid",
    borderWidth: 3, fontFamily:'CircularStd', fontSize:14,
    borderRadius: 0
  },
  "& .MuiInputBase-fullWidth": {
    margin: 0
  },"& .MuiInputAdornment-positionEnd": {position:"absolute",zIndex:1,right:0,
  },"& inpur:before": {content: "none"},

});
const CustomTextInputStyle = styled(TextareaAutosize )({
  backgroundColor: "#fff",
  minHeight: "22px",position: "relative",
  zIndex: 1,borderColor: "#000",
  width: "100%",paddingLeft: "5px",
  paddingRight: "5px",borderStyle: "solid",
  borderWidth: 3,fontFamily:'CircularStd', 
  fontSize:14,paddingTop:30,outline:"none !important",
  lineHeight:1,"& .MuiInputBase-fullWidth": {margin: 0},
  '&::placeholder': {
    color: '#ADB3BC',
    opacity: 1.3,
  },
  "& inpur:before": {content: "none"},"& .MuiInputAdornment-positionEnd": {
    position:"absolute",zIndex:1,right:0,},
});

const HelpCenterContainer = styled('div')({
  '& .leftSideWrapper':{
    paddingLeft: 'min(35px,4vw)', 
    paddingRight: 'min(35px,4vw)', 
    paddingTop: 40, 
    display:'flex',
    flexDirection:'column', 
    height:'100%',
  },
  '& .rightSide >div':{
    padding: "84 min(110px,8vw) 40",
  },
  '& .rightSideBox':{
    display: 'flex', 
    flexDirection:'column', 
    gap:'30px', 
    paddingTop:4, 
    marginTop:20
  },
  '& .forMobile':{
    display:'none'
  },
  "@media only screen and (max-width:640px)":{
    '& .leftSide':{
      display:'none !important'
    },
    '& .rightside':{
      height:'auto !important'
    },
    '& .rightSide >div':{
      paddingTop: 'min(45px,7vw)',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxSizing: 'border-box'
    },
    '& .rightSideBox':{
      marginTop: '45px',
      flexGrow: 1
    },
    '& .forMobile':{
      display: 'block'
    },
    '& .forDesktop':{
      display: 'none'
    }
  }
});

const ContactDialogContent = styled(DialogContent)({
  '& .contactBox':{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fff",
    paddingTop: '100px',
    paddingBottom: '60px',
    width: '407px',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '& .nameFields':{
    display: 'flex',
    gap: '30px'
  },
  '& .contact-submit':{
    paddingTop:50
  },
  '@media only screen and (max-width:640px)':{
    '& .contactBox':{
      paddingTop: 'min(80px,10vw)',
      paddingBottom: 'min(40px,5vw)'
    },
    '& .nameFields':{
      gap:'20px'
    }
  },
  '@media only screen and (max-width:450px)':{
    '& .nameFields':{
      flexDirection: 'column',
      alignItems: 'unset !important'
    },
    '& .nameFields >div':{
      maxWidth:'100%',
      flexBasis:'100%'
    },
    '& .nameFields >div:last-child':{
      paddingTop:'0 !important'
    },
    '& .contact-submit':{
      paddingTop:40
    },
    '& .fieldWrapper>div:first-child':{
      width: '100% !important'
    }
  }
})

const InputTextField = styled(TextField)({
  '& .MuiOutlinedInput-input':{
    textOverflow: "ellipsis"
  },
  '& .MuiOutlinedInput-notchedOutline':{
    borderWidth: '0 !important'
  }
})
// Customizable Area End
