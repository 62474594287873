import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {createRef} from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import * as Yup from "yup";
import { throttle } from "lodash";

let passwordSchema = {
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords must match"
      )
    })
};

export interface MsaFile {
  url: string,
  filename: string
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isChecked: boolean;
  showPasswordModal: boolean;
  firstName: string;
  lastName: string;
  phone: any;
  email: string;
  location: any;
  companyName: string;
  changedAccountDetails: any;
  clientTypeCheck: any;
  isLoading:boolean;
  showWarningUser: boolean;
  showWarningAdmin: boolean;
  errorFailureMessage: string;
  enablePasswordField: boolean;
  passwordSchema: any;
  btnConfirmPasswordShowHide: boolean;
  fieldErrorEmail: boolean;
  emailErrorMessage: string;
  fieldErrorFirstName: boolean;
  fieldErrorLastName : boolean;
  fieldErrorMessageFirstName: string;
  fieldErrorMessageLastName: string;
  email_enable : boolean;
  openSuccessModal : boolean;
  touchEmail : boolean;
  emailCheck : any;
  showFailureModal: boolean;
  checkAccountDetails : any;
  isDrawerOpen:boolean;
  rightSideComponent:string;
  msaDocumentsList:{msaUrl:string,msaLink:string, msaFiles:{filename:string,url:string}[]};
  selectedMsaFile:string;
  openDialogName: string;
  isMSADisplay: boolean;
  termOfService:{ title: string, content:string };
  privacyStatement:{ title: string, content: string};
  failureModalMessage:string;
  isSuspended : boolean ;
  suspendedMessage : string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  keyGetAccountDetails: string = "";
  keyPutNotification: string = "";
  keyChangeAccountDetails: string = "";
  requestGoToConfirmationCallId: string = "";
  confirmationPopUpId: string = "";
  msaApiId: string = "";
  getTermsOfServiceApiCallId: string = "";
  getPrivacyStatementCallId: string = "";
  userSuspendedApiCallId : string  =  "";
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  formikRef: any;
  checkLogoutApiCallId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.formikRef = createRef()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isChecked: false,
      showPasswordModal: false,
      firstName: "",
      lastName: "",
      phone: null,
      email: "",
      isLoading:true,
      location: "",
      companyName: "",
      changedAccountDetails: {},
      clientTypeCheck: "",
      showWarningUser: false,
      showWarningAdmin: false,
      errorFailureMessage: "",
      enablePasswordField: true,
      passwordSchema: passwordSchema,
      btnConfirmPasswordShowHide: true,
      emailErrorMessage: "",
      fieldErrorEmail: false,
      fieldErrorFirstName: false,
      fieldErrorLastName: false,
      fieldErrorMessageFirstName: "",
      fieldErrorMessageLastName: "",
      email_enable: false,
      openSuccessModal : false,
      touchEmail:false,
      emailCheck :"",
      showFailureModal : false,
      checkAccountDetails : {},
      isDrawerOpen:false,
      rightSideComponent: "Account",
      msaDocumentsList: {msaUrl:"",msaLink:"",msaFiles:[{filename:"",url:""}]},
      selectedMsaFile: "",
      openDialogName: "",
      isMSADisplay: true,
      termOfService:{ title: "", content: "" },
      privacyStatement:{ title: "", content: ""},
      failureModalMessage:"",
      isSuspended : false,
      suspendedMessage : "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestCallId != null) {
        const apiRequestCallData = {
          [this.keyGetAccountDetails]: (data: any) => this.accountDetailsRes(data),
          [this.checkLogoutApiCallId]:this.logout,
          [this.keyPutNotification]: (data: any) => this.notificationRes(data),
          [this.keyChangeAccountDetails]: (data: any) => this.changeAccountDetailsRes(data),
          [this.requestGoToConfirmationCallId]: (data:any) =>this.changePasswordRes(data),
          [this.getTermsOfServiceApiCallId]: (data:{title:string, content:string}[])=> this.fnTermOfServiceRes(data),
          [this.getPrivacyStatementCallId]: (data:{title:string, content:string}[])=> this.fnPrivacyStatementRes(data),
          [this.confirmationPopUpId] : (data:any) => {},
          [this.msaApiId] : (data:{data:{attributes:{msa_link:string, msa_files:{filename:"",url:""}[]}}}) => this.msaDetailsHandler(data),
          [this.userSuspendedApiCallId]: () => this.handleIsUserSuspended(message)
        }

        const callback = apiRequestCallData[apiRequestCallId];

        if (callback !== undefined) {
          callback(responseJson)
          this.msaResponseRender(callback.name, responseJson)
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.AccountDetailsAPI();
    this.msaDetailsApicall();
    this.termsOfServiceApi();
    this.privacyStatement();
    window.addEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },4000)

  handleIsUserSuspended = (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.status && responseJson.status === "suspended"){
      window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
      this.setState({isSuspended:true,suspendedMessage:responseJson.message})
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    this.setState({isSuspended:false,suspendedMessage:""})
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  msaResponseRender(apiID: string | null, responseJson : {data : {attributes: {msa_link: string | null, msa_files: []| MsaFile[]}}} ){
    if (apiID === this.msaApiId) {
      if (!responseJson.data.attributes.msa_link && !responseJson.data.attributes.msa_files.length) {
        this.setState({ isMSADisplay: false });
      } else {
        this.setState({ isMSADisplay: true });
      }
    }
  };

  goToCategory() {
    this.props.navigation.navigate("Categoriessubcategories");
  }
  goToHelpCenterFn() {
    this.props.navigation.navigate("HelpCenter");
  }

  goToProfileFn() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableuserProfiles");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToHomeFn() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToEnquiryFn() {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Enquiries");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  toggleSwitch = () => {
    
    this.setState({isChecked:!this.state.isChecked},()=> {
      this.notificationGetAPI();})
    
    
  }
  passwordModalClose = () => {
    this.setState({ showPasswordModal: false });
    this.formikRef.current?.resetForm({
      values: {
      password:"",
      confirmPassword:""
      }
    })
  }
  passwordModalOpen = () => {
    this.setState({ showPasswordModal: true });
    this.formikRef.current?.resetForm({
      values: {
      password:"",
      confirmPassword:""
      }
    })
  }
  passwordModal = () => {
    this.setState({ showPasswordModal: !this.state.showPasswordModal });
    this.formikRef.current?.resetForm({
      values: {
      password:"",
      confirmPassword:""
      }
    })
  }

  AccountDetailsAPI = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": token,
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyGetAccountDetails = requestMessageAPi.messageId;

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.getAccountDetailsEndPoint
    );

    requestMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
    return this.keyGetAccountDetails;
  }
  accountDetailsRes = (responseJson: any) => {
    let details = responseJson && responseJson.data.attributes;

    this.setState({
      firstName: details.first_name,
      lastName: details.last_name,
      phone: details.full_phone_number,
      email: details.email,
      location: details.location,
      companyName: details.company.name,
      clientTypeCheck: details.type,
      isChecked : details.email_enable,
      emailCheck :details.email,
      checkAccountDetails :details,
      isLoading:false,
    })
     removeStorageData("userName")
     setStorageData("userName", this.state.firstName);
  }

  notificationGetAPI = async () => {
    const token = await getStorageData("authToken")

    const header = {
      "Content-Type": "application/json",
      "token": token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyPutNotification = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPIMethodPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.keyPutNotification;
  };

  notificationRes = (responseJson :any) =>{
    const message = responseJson.message
    this.setState({
      isChecked : message === "email is disabled" ? false : true,
    })

  }

  changeAccountDetailsPutAPI = async (email : string) => {

    const token = await getStorageData("authToken")

    const header = {
      "token": token,
    };

    const formData = new FormData();
    formData.append(`account[first_name`, this.state.firstName)
    formData.append(`account[last_name]`, this.state.lastName)
    formData.append(`account[email]`,email)
    formData.append(`account[full_phone_number]`, this.state.phone)
    formData.append(`account[location]`, this.state.location)



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.keyChangeAccountDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeAccountDetailEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPIMethodPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.keyChangeAccountDetails;
  };

  changeAccountDetailsRes = (responseJson: any) => {
    const companyEmail = this.state.checkAccountDetails.company.email;
    const company_domain = companyEmail.split("@")[1];
    const  email_domain = this.state.email.split("@")[1];
    
    if (responseJson.account?.data || responseJson.warning ) { 
      this.setState({ fieldErrorEmail: false,fieldErrorFirstName :false,fieldErrorLastName:false,showFailureModal:false,showWarningAdmin: false, showWarningUser: false})

      if(this.state.checkAccountDetails.email== responseJson?.account?.data.attributes?.email) {
        this.setState({openSuccessModal:true})
        return this.AccountDetailsAPI();
      }

     
      else if(this.state.checkAccountDetails.email !== this.state.email){
        this.setState({email: this.state.email, openSuccessModal:true},()=> {this.AccountDetailsAPI(); this.confirmationPopUpApi()})
      }

    }
    else if(responseJson.account?.data){

       if(email_domain == company_domain){
       
        this.setState({email: this.state.email, openSuccessModal:true},()=> {this.AccountDetailsAPI(); this.confirmationPopUpApi()})
        }
        else if (email_domain == company_domain && this.state.checkAccountDetails.email== responseJson?.account?.data.attributes?.email){
          this.setState({openSuccessModal:true})
        return this.AccountDetailsAPI();

        }
    }
    else if (responseJson.errors) {
      if (responseJson.errors?.length) {
        this.setState({ showFailureModal: true ,showWarningAdmin: false, showWarningUser: false ,openSuccessModal:false,
          failureModalMessage:responseJson.errors.join(",")
        });
        return;
      }
    }
  }

  makeLogoutAPICall = async () => {
    const header = {
      "Content-Type": "application/json",
    };
    const body ={
      "data": {
          "attributes": { "email":  this.state.email },
          "type": "email_account"
          }
        }  

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkLogoutApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPIMethodDelete
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  logout = () => {
    
    removeStorageData('authToken');
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleInputChange = (e: any) => {
  
    let companyEmail = this.state.checkAccountDetails.company.email;
    let company_domain = companyEmail.split("@")[1];
  
    const { name = "", value } = e.target;
  
    const trimmedValue = value.trimStart();
   
    let errorName = `fieldError${name.slice(0,1).toUpperCase()}${name.slice(1)}`;

    if (name=="email" && value.trim() !== "" && value.split("@")[1] !== company_domain) {
      return this.setState({
        ...this.state,
        fieldErrorEmail: true,
        [name]: trimmedValue,
        emailErrorMessage: "You've entered an email address from an external domain. Please confirm this is correct before saving."
      });
    }

    if (name=="email" && value.trim() !== "" && value.split("@")[1] === company_domain) {
    
      return this.setState({
        ...this.state,
        fieldErrorEmail: false,
        [name]: trimmedValue,
      });
    }
  
    if (name=="email" && trimmedValue === "") {
      return this.setState({ ...this.state,fieldErrorEmail: true, emailErrorMessage: "Email can't be blank.",[name]: trimmedValue, });
    }
    

    if (name==="firstName" && value.trim() === "" ) {
      return this.setState({...this.state, fieldErrorFirstName: true, fieldErrorMessageFirstName: "Field can't be blank",[name]: trimmedValue
    })
    }
    if (name === "lastName" && value.trim() === ""){
      return this.setState({ ...this.state,fieldErrorLastName: true, fieldErrorMessageLastName: "Field can't be blank" ,[name]: trimmedValue})

    }


    this.setState({
      ...this.state,
      [name]: trimmedValue,
      [errorName]: false,
    });


  }

  hideFailureModal = () =>{
    this.setState({showFailureModal:false})
  }



  saveChangesBtn = () => {
   let email = this.state.checkAccountDetails.email;
   let companyEmail = this.state.checkAccountDetails.company.email;
   let company_domain = companyEmail.split("@")[1];

   if(this.state.email === email){
     this.setState({ showWarningAdmin: false,showWarningUser: false,email:email},()=>{
       this.changeAccountDetailsPutAPI(email);

     });
   }

   else if (this.state.email !== email && !this.state.email.includes(company_domain)) {
    if (this.state.clientTypeCheck == "ClientAdmin") {
      this.setState({ showWarningAdmin: true });
    }
    else if (this.state.clientTypeCheck == "ClientUser") {
      this.setState({ showWarningUser: true });
    }
  }

  else if (this.state.email.includes(company_domain)) {
    this.setState({ showWarningAdmin: false,showWarningUser: false,email:this.state.email},()=>{
      this.changeAccountDetailsPutAPI(this.state.email);

    });
  }
}

  confirmBtn = () => {
    this.changeAccountDetailsPutAPI(this.state.email);
    this.setState({showWarningAdmin: false,showWarningUser: false})
    removeStorageData("userName")
    setStorageData("userName", this.state.firstName);
  }

  navigateTo = (path: any) => {
    this.props.navigation.navigate(path);
  }

  hideWarningModalUser = () => {
    this.setState({ showWarningUser: false ,fieldErrorEmail:false,email:this.state.checkAccountDetails.email});
  }

  hideWarningModalAmin = () => {
    this.setState({ showWarningAdmin: false,fieldErrorEmail:false,email:this.state.checkAccountDetails.email,emailErrorMessage :""});
  }

  resetErrorMessage = () => {
    this.setState({ errorFailureMessage: '' });
  };

  goToConfirmationAfterPasswordChange = async (values: {
    password: any;
    confirmPassword: any;
  }) => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPt
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    const httpBody = {
      token: await getStorageData("authToken"),
      password: values.password,
      confirm_password: values.confirmPassword
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPIMethodPut
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.requestGoToConfirmationCallId;
  }

  changePasswordRes = (responseJson: any) => {
    if (responseJson.message === "Password updated successfully") {
      this.setState({ showPasswordModal: false, openDialogName: "passwordUpdated"});
    }
    else {      
      this.setState({
        errorFailureMessage: responseJson.message,
      });
    }
  }

  closePopup = () =>{
    this.setState({openDialogName: ""})
  }

  goToQuote = () =>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }

  goToSettings(){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
  hideSuccessModal = () =>{
    this.setState({openSuccessModal : !this.state.openSuccessModal})
  }
 
 
  confirmationPopUpApi = async () => {
     const tokenn = await getStorageData("authToken")
     const header = {"token": tokenn,
    };

    const formData = new FormData();
    formData.append(`account[first_name`, 
    this.state.firstName)
    formData.append(`account[last_name]`, 
    this.state.lastName)
    formData.append(`account[email]`, 
    this.state.email)
    formData.append(`account[full_phone_number]`, 
    this.state.phone)
    formData.append(`account[location]`, 
    this.state.location)



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmationPopUpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmationPopEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPIMethodPut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.confirmationPopUpId;
  };
  
  toggleDrawer=()=>{
    this.setState({isDrawerOpen: !this.state.isDrawerOpen});
  }

  handleDrawerClick=(component:string)=>{
    this.setState({rightSideComponent: component}, () => this.toggleDrawer())
  }

  msaDetailsHandler = (data:{data:{attributes:{msa_link:string, msa_files:{filename:"",url:""}[]}}}) =>{
    const attributes = data?.data?.attributes;
    this.setState({msaDocumentsList:{msaUrl: attributes?.msa_link.replace('view?usp=sharing', 'preview') || "", msaLink: attributes?.msa_link || "", msaFiles:attributes?.msa_files || []}, selectedMsaFile: attributes?.msa_files.length>0 ? attributes.msa_files[0].url+'#toolbar=0&frameBorder=0&scrollbars=0' : attributes.msa_link.replace('view?usp=sharing', 'preview')})
  }

  handlePreview = (url:string) =>{
    this.setState({selectedMsaFile: url})
  } 

  msaDetailsApicall = async () =>{
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.msaApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.msaEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fnTermOfServiceRes = (responseJson: { title:string, content:string }[]) =>{
    const res = responseJson[0];
    this.setState({termOfService: { title: res.title , content: res.content}})
  }

  fnPrivacyStatementRes = (responseJson: { title:string, content:string }[]) =>{
    const res = responseJson[0];
    this.setState({privacyStatement: { title: res.title , content: res.content}})
  }

  termsOfServiceApi(){    
    const header = {"Content-Type": configJSON.validationApiContentType}
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)); 
    this.getTermsOfServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.termsServiceApi); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  privacyStatement(){
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPrivacyStatementCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.privacyStatementApi); 
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    const header = {"Content-Type": configJSON.validationApiContentType}
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}