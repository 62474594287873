Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.btnExampleTitle = "Submit";
exports.httpGetMethod = "GET"
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";
exports.getInvoicesEndPoint = '/get_invoices?filter_by=month'
exports.invoiceTex = "Invoices";
exports.supsendedModalTitle = "Account Suspended";
exports.supspendedModalOkBtn = "OK";
exports.suspendedUserEndPoint =  "/account_block/accounts/check_company_suspension";
// Customizable Area End