import React, { FunctionComponent, useEffect, } from "react";
import SettingsIcon from '@material-ui/icons/Settings';
import {
  StyleSheet,
  Text,
  View,
} from "react-native";
import {
  Container,
  Grid, SwipeableDrawer, Box, Typography, Backdrop
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
const iconNotification = require("../../blocks/settings2/assets/1043817464cFxzonYRDJMRVgjEbjIYjo.png");
const iconUpArrow = require("./iconUpArrow.png");
const iconMenu = require("./iconMenu.png");
import { createProfile } from "../../blocks/customisableuserprofiles/src/assets";
import { imgClock } from "../../blocks/landingpage/src/assets";
import { getStorageData } from "../../framework/src/Utilities";
import axios from "axios";
const downArrow = require("./iconUpArrow.png");
interface Props {
  helpCenterText?: string;
  logoUrl: string;
  homeText: string;
  createImageUrl: string;
  notificationUrl?: string;
  helpCenterNotificationUrl?: string
  userUrl: string;
  settingsIcon?: boolean;
  onPressHome: () => void;
  onClickHelpCenter: () => void;
  onClickViewProfile: () => void;
  onClickViewEnquiries?: () => void;
  onClickSettings?: () => void;
  onClickQuote?: () => void;
  quoteUrl?: any;
}

const Header: FunctionComponent<Props> = (props) => {

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [openNotification, setNotification] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState('')
  const [userProfileData, setUserProfileData] = React.useState<any>()
  const [unreadNotification, setUnreadNotifications] = React.useState(0)

  const [notificationData, setNotificationData] = React.useState<any[]>([])
  const [intervalId, setIntervalId] = React.useState<any>("")
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  const fnNotification = () => {
    setNotification(prev => !prev);
  }

  const getNotificationsList = async () => {
    let token: any = await getStorageData("authToken")
    const header = {
      "Content-Type": "GET",
      token: token
    };
    try {
      const response = await axios.get(`https://londonfilmedeventsfinalbuild-390988-ruby.b390988.dev.eastus.az.svc.builder.cafe/bx_block_notifications/notifications/`,
        {
          headers: header
        }
      );
      const { data } = response;
      if (data && data.data) {
        const sortedData = data.data.sort((a:any, b:any) => 
          new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime()
        );
        setNotificationData(sortedData)
      } 
    }catch (error) {
      throw error
    }

  };

  const getLoggedInUserProfile = async () => {
    let token: any = await getStorageData("authToken")
    const header = {
      "Content-Type": "GET",
      token: token
    };
    try {
      const response = await axios.get(`https://londonfilmedeventsfinalbuild-390988-ruby.b390988.dev.eastus.az.svc.builder.cafe/account_block/accounts/specific_account`,
        {
          headers: header
        }
      );
      const { data } = response;
      if (data && data.data) {
        setUserProfileData(data.data)
        if(data.data?.attributes?.profile_picture !="No File Attached"){
          return setUserProfile(data.data?.attributes?.profile_picture)
        }else {
          return setUserProfile(createProfile)
        }
      } 
    } catch (error) {
      throw error
    }
  const notificationData1 = [{ image: createProfile, userName: "Agora", packageName: "Live Streaming", packageDate: "01/12/2022", packageStatus: "Approved", time: "2h" }, { image: createProfile, userName: "Agora", packageName: "Live Streaming", packageDate: "01/12/2022", packageStatus: "Approved", time: "2h" }]
  
  function decodeJWT(token:any) {
    const payloadBase64 = token.split('.')[1];
    const decodedPayload = atob(payloadBase64);
    return JSON.parse(decodedPayload);
  }

  function isTokenExpired(token:any) {
    const decoded = decodeJWT(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  }

  const handleLogout = async () => {
    await localStorage.removeItem("authToken");
    window.location.href = "/login";
  }   

  const startTokenExpirationCheck = (token:any) => {
    if (intervalId) clearInterval(intervalId);

    const newId = setInterval(() => {
      const expired = isTokenExpired(token);
      if (expired) {
        handleLogout();
        clearInterval(newId); 
      }
    }, 60000);

    setIntervalId(newId);
  };

  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  }

  useEffect(() => {
    const token = getAuthToken();
    startTokenExpirationCheck(token);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  };

  const viewInquiry = (id: any) => {
    window.location.assign(`/Enquiries?id=${id}`);
    fnNotification()
  };

  const formatDate = (date:any) => {
    const updatedAt = new Date(date);
    return `${updatedAt.getMonth() + 1}/${updatedAt.getDate()}/${updatedAt.getFullYear()}`;
  }

  const getRelativeTime = (date:any) => {
    const updatedAt = new Date(date);
    const now = new Date();
    const timeDiff = Math.abs(now.getTime() - updatedAt.getTime());

    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (hoursDiff < 1) {
      const minutesDiff = Math.floor(timeDiff / (1000 * 60));
      return `${minutesDiff} min`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff}hr`;
    } else {
      return `${daysDiff}d`;
    }
  }

  const getUnreadNotificationCount = (notificationRes:any) => {
    const unreadNotifications = notificationRes ? notificationRes.filter((data:any ) => data.attributes.is_read === false).length : 0;
    setUnreadNotifications(unreadNotifications) 
  }

  useEffect(() => {
    getNotificationsList()
    getLoggedInUserProfile()
  }, []);

  useEffect(() => {
    getUnreadNotificationCount(notificationData)
  }, [notificationData]);
  return (
    <>
      <View
        style={{
          backgroundColor: '#594FF5',
          paddingTop: 10,
          paddingBottom: 10,
          borderBottomColor: "#000",
          borderBottomWidth: 3,
          borderStyle: "solid",
        }}
      >
        <CustomContainer disableGutters maxWidth={false}>
          <Grid container spacing={0} >
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <View
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  style={{
                    backgroundColor: "00FFFFFF",
                    height: 52,
                    marginRight: 40,
                    cursor: "pointer"
                  }}
                  src={props.logoUrl}
                  alt="Logo"
                  onClick={props.onPressHome}
                />
              </View>
              <Text testID="homeNav"
                style={styles.headerText}
                onPress={props.onPressHome}>
                {props.homeText}
              </Text>
              <Text testID="enquiriesNav" style={styles.headerText} onPress={props.onClickViewEnquiries}>
                Enquiries
              </Text>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "self-end",
                  justifyContent: "center",
                  paddingRight: "30px"

                }}
              >
                <div onClick={props.onClickQuote}>
                  <div>
                    <img src={props.quoteUrl} style={{ marginRight: "2px", height: "18px", width: "18px", marginTop: "2px" }} />
                  </div>
                </div>
                <div style={{
                  color: "#f6f0ffff",
                  fontSize: 16,
                  fontWeight: 500,
                  fontFamily: "CircularStdBold", cursor: 'pointer'
                }} onClick={props.onClickQuote} className="resHeaderQuote">Quote</div>
              </div>

              {props.notificationUrl && <div style={{
                cursor: 'pointer'
              }}>
                <img
                  style={{
                    backgroundColor: "00FFFFFF",
                    height: 22,
                    width: 22,
                    cursor: 'pointer'
                  }}
                  src={props.notificationUrl}
                  alt="image_notification"
                />
              </div>}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  cursor: 'pointer'
                }}
              >
                <div onClick={props.onClickViewProfile}>
                  <img
                    style={{
                      backgroundColor: "00FFFFFF",
                      height: 22,
                      width: 22,
                      cursor: 'pointer',
                    }}
                    src={props.userUrl}
                    alt="image_create"
                  />
                </div>
                <div style={{
                  color: "#f6f0ffff", fontSize: 16, fontWeight: 500, fontFamily: "CircularStdBold", paddingLeft: "0px", paddingRight: "30px"
                }} onClick={props.onClickViewProfile}>{localStorage.getItem('userName')}</div>
                {props.helpCenterNotificationUrl && <div style={{ position: "relative", paddingRight: "30px", cursor: 'pointer' }}>
                  <img
                    style={{
                      backgroundColor: "transparent",
                      height: 22,
                      width: 22,
                      cursor: 'pointer'
                    }}
                    src={props.helpCenterNotificationUrl}
                    alt="image_notification"
                    onClick={fnNotification}
                  />
                            
                  {unreadNotification > 0 && <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "#FF0000",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                      height: "16px",
                      width: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      fontWeight: "bold",
                      transform: "translate(-133%, -74%)" 
                    }}
                  >
                    {unreadNotification}
                  </span>}
                </div>
                }

                <div style={{
                  color: "#f6f0ffff",
                  fontSize: 16,
                  fontWeight: 500,
                  fontFamily: "CircularStdBold", paddingRight: "30px"
                }} onClick={props.onClickHelpCenter}>{props.helpCenterText}</div>


                {props.settingsIcon && <SettingsIcon style={{ color: "#fff", width: 95, height: 20, marginLeft: "-2rem" }} onClick={props.onClickSettings} />}
              </div>
            </Grid>
          </Grid>
        </CustomContainer>
        <MobileHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              style={{
                backgroundColor: "00FFFFFF",
                height: 40,
                cursor: "pointer"
              }}
              src={props.logoUrl}
              alt="Logo"
              onClick={props.onPressHome}
            />
            <div style={{
              cursor: 'pointer', display: "flex", alignItems: "center"
            }}>
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 23,
                  width: 25,
                  cursor: 'pointer',
                  paddingRight: 39,
                }}
                src={iconNotification}
                alt="image_notification"
                onClick={fnNotification}
              />

              <img src={iconMenu} onClick={toggleDrawer} style={{ height: 16 }} />
            </div>
          </div>
        </MobileHeader>
      </View>
      <CustomSwipeableDrawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer} >
        <MobileHeader style={{ height: "65px", }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "10", boxSizing: "border-box" }}>
            <img
              style={{
                backgroundColor: "00FFFFFF",
                height: 40,
                cursor: "pointer"
              }}
              src={props.logoUrl}
              alt="Logo"
              onClick={props.onPressHome}
            />
            <div style={{
              cursor: 'pointer', display: "flex", alignItems: "center"
            }}>
              <img
                style={{
                  backgroundColor: "00FFFFFF",
                  height: 23,
                  width: 25,
                  cursor: 'pointer',
                  paddingRight: 39,
                }}
                src={iconNotification}
                alt="image_notification"
              />

              <img src={iconMenu} onClick={toggleDrawer} style={{ height: 16 }} />
            </div>
          </div>
        </MobileHeader>
        <div style={{ border: "3px solid black", paddingTop: "15px", paddingBottom: "12px", backgroundColor: "#DEDCFD", display: "flex", flexDirection: "column", gap: 23, height: 298, fontWeight: 700, boxSizing: "border-box", alignItems: "center", cursor: "pointer", transition: "height 1.9s ease-in-out", }}>
          <CustomList onClick={props.onClickViewProfile}>{localStorage.getItem('userName')}</CustomList>
          <CustomList onClick={props.onPressHome}>Home</CustomList>
          <CustomList onClick={props.onClickViewEnquiries}>Enquiries</CustomList>
          <CustomList onClick={props.onClickQuote}>Quote</CustomList>
          <CustomList onClick={props.onClickHelpCenter}>{props.helpCenterText}</CustomList>
          <CustomList onClick={props.onClickSettings}>Settings</CustomList>
          <div>
            <img src={iconUpArrow} onClick={() => setDrawerOpen(false)} style={{ height: 10, paddingTop: 6 }} />
          </div>
        </div>
      </CustomSwipeableDrawer>
      <Backdrop
        style={{
          background: "transparent",
          zIndex:1100
        }}
        open={openNotification}
        onClick={(e) => { setNotification(false) }
        }
      >
        <NotificationContainer style={{zIndex: 1250, height: !notificationData.length ? 200: 367}}>
          <div onClick={(event) =>
            event.stopPropagation()} style={{ cursor: 'pointer' }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 10 10 10", marginTop: "-9px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ fontFamily: "CircularStdBold", fontSize: 14, fontWeight: 450 }}>Notification</Typography>
                <Typography style={{ fontFamily: "CircularStd", fontSize: 14, fontWeight: 700 }}>&nbsp;({unreadNotification})</Typography>
              </div>
              <Typography style={{
                color: "#594ff5",
                fontSize: 14,
                fontFamily: "CircularStd",
                fontWeight: 700,
                cursor: 'pointer'
              }}>Clear All</Typography>
            </div>
            <div style={{ padding: "0 5 0 5", height: notificationData.length < 3 ? 293 : ''}}>
              {notificationData.length > 0 ? notificationData.map((data:any) => {
                return (
                  <NotificationLayout style={{cursor:"pointer", backgroundColor:data.attributes.is_read === true ? "#f8f3f3":"#F8F8F8"}}>
                    <div
                      style={{ display: "flex", flexDirection: "row", alignItems:"center" }}
                      onClick={() => viewInquiry(data.attributes.inquiry_id)}
                    >
                      <img
                        src={userProfile}
                        style={{
                          marginRight: 10,
                          borderColor: "#000",
                          borderWidth: 3,
                          width: 56,
                          height: 56,
                          border: "3px solid black",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          <Typography style={{
                            fontSize: 14,
                            color: "#000",
                            textTransform:"capitalize",
                            fontFamily: "CircularStd", fontWeight: 700,
                          }}>{data.attributes.headings === "email_update" ? `${userProfileData?.attributes?.first_name} ${userProfileData?.attributes?.last_name}` :data.attributes.event_name}</Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {data.attributes.headings === "email_update" ? "Email changed" : data.attributes.service_name}
                          </Typography>
                          <Typography style={{
                            color: "#5A5A5A",
                            fontSize: 14, fontWeight: 450,
                            fontFamily: "CircularStd"
                          }}>
                            {formatDate(data.attributes.updated_at)}
                          </Typography>


                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <Typography style={{
                            color: data.attributes.inquiry_status === "approved"
                                  ? "#00BFBA"
                                  : data.attributes.inquiry_status === "rejected"
                                  ? "#FF5B74"
                                  : "#5A5A5A",
                            fontSize: 14,
                            textAlign: "center",
                            fontWeight: 700,
                            fontFamily: "CircularStd",
                            textTransform: "capitalize"
                          }}>{data.attributes.inquiry_status === "partial_approved" ? "Review Quote" : data.attributes.inquiry_status}</Typography>
                          <div
                            style={{
                              marginTop: "auto",
                              justifyContent: "flex-end",
                              display: "flex",
                              width:74
                            }}
                          >
                            <img
                              src={imgClock}
                              style={{ marginRight: "2px" }}
                            />
                            <Typography
                              style={{
                                color: "#64748B",
                                fontSize: 12,
                                marginRight: 0,
                                fontWeight:450,
                                height: 9,
                                fontFamily: "CircularStd"
                              }}
                            >
                              {getRelativeTime(data.attributes.updated_at)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NotificationLayout>
                )
              }):(
                <div>
                  <Text style={styles.noNotificationtext}>No new notifications.</Text>
                </div>
              )}
            </div>
            <img src={downArrow}
              style={{ marginLeft: "50%", marginTop: 5, cursor: "pointer" }}
              onClick={
                () => {
                  setNotification(false)
                }
              }
            />
          </div>
        </NotificationContainer>
      </Backdrop>

      <Backdrop
        style={{
          background: "transparent"
        }}
        open={openNotification}
        onClick={(e) => { setNotification(false) }
        }
      >
      </Backdrop>
    </>
  );
};

export default Header;

const styles = StyleSheet.create({
  headerText: {
    color: "#f6f0ffff",
    fontSize: 16,
    letterSpacing: 0,
    paddingLeft: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingRight: 10,
    fontFamily: "CircularStdBold",
  },
  noNotificationtext: {
    color: "#5A5A5A",
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "CircularStd",
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height: 126
  },
  textHeader: {
    color: "#f6f0ffff",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "CircularStdBold",
  },
  container: {
    width: "100%",
    backgroundColor: "#000",
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
});


const CustomContainer = styled(Container)({
  paddingLeft: "40px", paddingRight: "5px",
  "@media screen and (min-width:320px) and (max-width:900px)": {
    display: "none",
  },
  "@media screen and (min-width:900px) and (max-width:1300px)": {
    display: "block",
  }
})

const MobileHeader = styled(`div`)({
  display: "none",
  "@media screen and (min-width:320px) and (max-width:900px)": {
    display: "block",
    boxSizing: "border-box",
    padding: "4 8 4 8",
    backgroundColor: "#594FF5"
  },
  "@media screen and (min-width:900px) and (max-width:1300px)": {
    display: "none",
  }
})

const CustomSwipeableDrawer = styled(SwipeableDrawer)({
  display: "none",
  "@media screen and (min-width:320px) and (max-width:900px)": {
    display: "block",
  },
  "@media screen and (min-width:900px) and (max-width:1300px)": { display: "none" }
})

const CustomList = styled(`div`)({
  "&:hover": {
    textDecoration: "underline",
    textDecorationColor: "1px solid black",
  }
})

const NotificationContainer = styled(`div`)({
  width: 350, border: "3px solid black", marginTop: 40,
  backgroundColor: "#FDF8F8",
  position: "absolute",
  top: "32px",
  right: "144",
  overflowY: "auto",
  '&::before': {
    content: "''",
    position: 'relative',
    top: "-43px",
    left: '72%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '25px solid black',
    zIndex: -2,
    cursor: 'pointer'
  },
  '&::after': {
    content: "''",
    position: "relative",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: "-393px",
    right: "-69.2%",
    marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },

  "@media screen and (min-width:320px) and (max-width:899px)": {
    right: "0"
  },
  "@media screen and (min-width:900px)": {
    width: 350, border: "3px solid black", height: 367, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "32px",
    right: "144",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '72%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-393px",
      right: "-69.2%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:2555px)": {
    width: 350, border: "3px solid black", height: 367, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "32px",
    right: "144",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-47px",
      left: '72%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-397px",
      right: "-69.2%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  }
})

const ResNotificationContainer = styled(`div`)({
  width: "100%",
  boxSizing: "border-box",
  border: "3px solid black", height: 268, marginTop: 40,
  backgroundColor: "#FDF8F8",
  position: "absolute",
  top: "28px",
  right: "0",
  '&::before': {
    content: "''",
    position: 'relative',
    top: "-43px",
    left: '72%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '25px solid black',
    zIndex: -2,
    cursor: 'pointer'
  },
  '&::after': {
    content: "'W'",
    position: "relative",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: "-293px",
    right: "-69.2%",
    marginLeft: "10px",
    borderBottom: "25px solid #FDF8F8",
    zIndex: 1
  },
  "@media screen and (min-width:300px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '74%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-71.2%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:370px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '75%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-72.3%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:380px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '75%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-72.4%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:400px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '76%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-73.3%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:403px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '77%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-74.3%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:410px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '77%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-74.6%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:420px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '78%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-75.5%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:440px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '79%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-76.7%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:460px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '80%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-77.7%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:486px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '81%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-78.9%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:500px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '82%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-80.1%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:540px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '83%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-81.2%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:580px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '84%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-82.2%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:600px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '85%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-83.3%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:650px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '86%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-84.5%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:685px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '87%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-85.6%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:740px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '88%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-86.6%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:800px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '89%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-87.6%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:890px)": {
    width: "100%",
    boxSizing: "border-box",
    border: "3px solid black", height: 268, marginTop: 40,
    backgroundColor: "#FDF8F8",
    position: "absolute",
    top: "28px",
    right: "0",
    '&::before': {
      content: "''",
      position: 'relative',
      top: "-43px",
      left: '90%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '25px solid black',
      zIndex: -2,
      cursor: 'pointer'
    },
    '&::after': {
      content: "''",
      position: "relative",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "-293px",
      right: "-88.8%",
      marginLeft: "10px",
      borderBottom: "25px solid #FDF8F8",
      zIndex: 1
    },
  },
  "@media screen and (min-width:900px)": {
    display: "none"
  }
})

const NotificationLayout = styled(`div`)({
  padding: "8px 7px",
  borderRadius: 2,
  marginBottom: 20,
  boxShadow: "0px 10px 20px 0px #00000008",
  width: "auto",
  "&:hover": { 
    cursor: "pointer"
  }
});
