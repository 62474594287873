Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";
exports.subTotal = "Sub Total";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "bx_block_categories/categories?only_bespoke=true";
exports.categoryAPIEndPointAllPackages = "/bx_block_categories/categories?no_bespoke=true";
exports.previouseCategoryEndPoint = "/inquiries_list";
exports.bespokeCategoryEndPoint = "/bx_block_categories/categories?only_bespoke=true";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.getInquiryEndpoint = "inquiry";
exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.home = "Home";
exports.requestQuote = "Request a Quote";
exports.sType = "Service Type";
exports.createAccount = "Create your event";
exports.chooseServiceType = "Choose your service type";
exports.enterEventDetails = "Enter event details";
exports.reviewSubmit = "Review and submit";
exports.bespokePackage = "Your Packages";
exports.previousePackage = "Previous Packages";
exports.allPackage = "All Packages";
exports.selectPackage = "Select Package";
exports.supportText = "Please choose what service you're looking for support with. If the service isn't listed below or you want multiple services, please select Bespoke Request.";
exports.comingSoon = "Coming Soon...";
exports.createCustomFormEndpoint = "create_inquiry";
exports.ApiContentType = "application/json";
exports.createPreviousCustomFormEndpoint = "/previous_package"
exports.supsendedModalTitle = "Account Suspended";
exports.supspendedModalOkBtn = "OK";
exports.suspendedUserEndPoint = "/account_block/accounts/check_company_suspension";
// Customizable Area End