import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { createProfile } from "../../customisableuserprofiles/src/assets";
import { throttle } from "lodash";

export const configJSON = require("./config");
interface DataStructure {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      is_bespoke: boolean;
      service_category: string;
      sub_categories: {
        data: {
          id: string;
          type: string;
          attributes: {
            id: number;
            name: string;
            start_from: number;
            duration: number;
            color_theme: string | null;
            parent_id: number;
            actual_price: number;
            category_id: number;
            image: string;
            features: {
              data: [];
            };
            default_coverages: {
              data: [];
            };
          };
        }[];
      };
      start_from_price: number;
      image: string;
    };
  }[];
  meta: {
    message: string;
  };
  message: string;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: string;
  loading: boolean;
  categorytDetails: any;
  showModel: boolean;
  token: string;
  categoryList: any;
  previousCategoryList: any
  subcategory: any;
  isLoading: boolean;
  currentPage: number;
  recordsPerPage: number;
  isLoadingPrevious: boolean;
  sortDirection: string;
  openDialogName: string;
  customForm: any;
  categoryId: number;
  groupByRequiredInformation: any;
  packageSlectedList: any[];
  packageSelectedItem: any[];
  groupByAddons: any;
  addonCoverage: any;
  groupByServices: any;
  groupByAdditionalAddon: any[];
  groupByCoverage: any;
  hoverIndex: any;
  base_service_detail: any;
  groupBySection: any;
  initialValueField: any;
  isViewLoading: boolean;
  companyProfilePicture: string;
  yourPackageMessage: string;
  yourPackageMessageBoolean: boolean;
  errorMessage: string;
  isSuspended : boolean;
  suspendedMessage : string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  getBespokeCategoriesApiCallId: any
  createInquiryId: any;
  getPreviousCategoriesApiCallId: any;
  getInquiryId: any;
  calculatedAmountAPiCallId: any;
  getProfileAccountID: any;
  userSuspendedApiCallId : string  =  "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseToken),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      category: "bespoke",
      loading: false,
      categorytDetails: [],
      showModel: false,
      token: "",
      categoryList: [],
      previousCategoryList: [],
      subcategory: {},
      isLoading: true,
      isLoadingPrevious: false,
      currentPage: 1,
      recordsPerPage: 12,
      sortDirection: "asc",
      openDialogName: "",
      customForm: {},
      groupByAdditionalAddon: [],
      addonCoverage: [],
      packageSlectedList: [],
      packageSelectedItem: [],
      groupByAddons: [],
      groupByServices: [],
      categoryId: 0,
      companyProfilePicture: "",
      groupByRequiredInformation: [],
      groupByCoverage: {},
      base_service_detail: {},
      groupBySection: {},
      hoverIndex: null,
      initialValueField: {},
      isViewLoading: false,
      yourPackageMessage: "",
      yourPackageMessageBoolean: false,
      errorMessage: "",
      isSuspended : false,
      suspendedMessage : "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    window.addEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionResponseToken)
    );
    this.send(msg);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isSessionResponseToken(message)) {
      let token = message.getData(getName(MessageEnum.SessionResponseData));
      if (token == undefined) {
        token = await getStorageData("authToken")
      }
      this.setState({ token: token }, () => {
        this.getBespokeCategories();
        this.fetchProfileData();
      });
      return;
    }

    if (this.isRestAPIResponse(message)) {
      const apiRequestCallId = this.getApiRequestCallId(message);
      const responseJson = this.getResponseJson(message);
      const errorResponse = this.getErrorResponse(message);

      if (this.hasErrors(responseJson, apiRequestCallId)) {
        this.handleError(responseJson);
        return;
      }

      this.handleApiResponse(apiRequestCallId, responseJson, errorResponse);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if(apiRequestCallId === this.userSuspendedApiCallId) {
      this.handleIsUserSuspended(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },4000)

  handleIsUserSuspended = (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.status && responseJson.status === "suspended"){
      this.setState({isSuspended:true,suspendedMessage:responseJson.message})
      window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    this.setState({isSuspended:false,suspendedMessage:""})
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  allPackagesFn = (responseJson: DataStructure) => {
    if (responseJson.message) {
      this.setState({ yourPackageMessageBoolean: true, yourPackageMessage: responseJson.message, isLoading: false })
    }
    else { this.setState({ categoryList: responseJson.data, isLoading: false, yourPackageMessageBoolean: false }); }
  }

  yourPackagesFn = (responseJson: DataStructure) => {
    if (responseJson.message) {
      this.setState({ yourPackageMessageBoolean: true, yourPackageMessage: responseJson.message, isLoading: false })
    }
    else {
      this.setState({ categoryList: responseJson.data, isLoading: false, yourPackageMessageBoolean: false });
    }
  }

  onAllPackagesClick = () => {
    this.setState({ categoryList: [] })
    this.getCategories()
  }
  onPreviousPackagesClick = () => {
    this.setState({ isLoadingPrevious: true })
    this.getPreviousCategories()
  }
  handleViewEnquiry(responseJson: any) {
    this.setState({ customForm: responseJson.inquiry.data });
    this.setState({ categoryId: responseJson.inquiry.data.attributes.service_id })
    if (responseJson.inquiry.data.attributes.base_service_detail?.data?.attributes) {
      this.setState({ base_service_detail: responseJson.inquiry.data.attributes.base_service_detail.data.attributes });
    }
    const { base_service_detail } = responseJson.inquiry.data.attributes
    const { extra_services_detail } = responseJson.inquiry.data.attributes

    let fields: any[] = [];
    if (extra_services_detail.data.length > 0) {
      let AddonListArray = extra_services_detail.data.map((inputList: any) => {
        return inputList.attributes.input_values.data
      }).flat()

      fields = [...base_service_detail.data.attributes.input_values.data, ...AddonListArray]
    } else {
      fields = base_service_detail.data.attributes.input_values.data
    }

    let respone: any = {}
    fields.forEach((item: any) => { respone[String(item.id)] = item.attributes.user_input });
    this.setState({ initialValueField: respone })


    const groupBySection = base_service_detail.data.attributes.input_values.data.reduce((group: any, inputDataSection: any) => {
      const { section } = inputDataSection.attributes.input_field;
      group[section] = group[section] ?? [];
      group[section].push(inputDataSection);
      return group;
    }, {});
    this.setState({ groupByRequiredInformation: groupBySection["required_information"] });
    this.setState({ groupByAddons: groupBySection["addon"] });
    this.setState({ groupByServices: groupBySection["services"] });
    this.setState({ groupByAdditionalAddon: extra_services_detail.data });
    this.setState({ groupBySection: groupBySection });
    this.setState({ isViewLoading: false })
  }
  selectViewCompanyProfile = () => {
    let profileImageSrc;
    if (
      this.state.companyProfilePicture !== null
    ) {
      profileImageSrc =
        this.state.companyProfilePicture;
    } else {
      profileImageSrc =
        createProfile;
    }
    return profileImageSrc;
  };
  onBespokePackagesClick = () => {
    this.setState({ categoryList: [] })
    this.getBespokeCategories()
  }
  viewInquiry = (id: any) => {
    this.setState({ openDialogName: "ViewEnquiryStatus", isViewLoading: true }, () => {
      this.getInquiry(id)
    });
  };
  fetchProfileData = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID
      = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/specific_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTimeString = (value: any) => {
    if (!value) return '';
    if (moment.isMoment(value)) {
      return `${value.format("HH:mm")} GMT`;
    } else if (typeof value === 'string') {
      return `${value} GMT`;
    } else if (value instanceof Date) {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes} GMT`;
    } else {
      return '';
    }
  };
  calculatedAmount = (id: any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.calculatedAmountAPiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/calculate_cost?inquiry_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getInquiry = async (id: any) => {
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInquiryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInquiryEndpoint + `/${id}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };
  onCancel = () => {
    this.setState({ openDialogName: "" })
  }
  getCategories = () => {
    if (!this.state.token) {
      return;
    }
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPointAllPackages
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSortClick = (field: any) => {
    const { sortDirection, previousCategoryList } = this.state;
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    this.setState({ hoverIndex: field });

    previousCategoryList && previousCategoryList.inquiry?.data?.sort((a_data: { attributes: { service_name: string, event_name: string, event_date: number | string | Date } }, b_data: { attributes: { service_name: string, event_name: string, event_date: number | string | Date } }) => {
      let valueA, valueB;
      switch (field) {
        case 'package':
          valueA = a_data.attributes.service_name;
          valueB = b_data.attributes.service_name;
          break;
        case 'eventName':
          valueA = a_data.attributes.event_name;
          valueB = b_data.attributes.event_name;
          break;
        case 'eventDate':
          valueA = new Date(a_data.attributes.event_date).getTime();
          valueB = new Date(b_data.attributes.event_date).getTime();
          break;

        default:
          break;
      }

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return sortDirection === 'asc' ? 1 : -1;
      if (valueB === null) return sortDirection === 'asc' ? -1 : 1;

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return sortDirection === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else {
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
        }
      }
    });

    this.setState({ sortDirection: newSortDirection, previousCategoryList: previousCategoryList });
  };

  getPreviousCategories = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPreviousCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.previouseCategoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBespokeCategories = () => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBespokeCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateCustomForm = async (categoryData: any) => {
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      inquiry: { service_id: categoryData.attributes.category_id, sub_category_id: categoryData.attributes.id }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createInquiryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomFormEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateBespokeCustomForm = async (categoryData: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = { inquiry: { service_id: categoryData.attributes.id, sub_category_id: categoryData.attributes.sub_categories.data[0].id } };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createInquiryId =
      requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createCustomFormEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    runEngine.sendMessage(
      requestMessage.id, requestMessage);
  };

  navigatePreviousCustomForm = async (id: any) => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: this.state.token
    };

    const httpBody = {
      previous_package: {
        inquiry_id: id
      }
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createInquiryId =
      requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createPreviousCustomFormEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    runEngine.sendMessage(
      requestMessage.id, requestMessage);
  };



  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };

  setSubcategory = (data: any) => {
    const lent = data.attributes.sub_categories.data.length;
    if (data.attributes.is_bespoke === true) {
      this.navigateBespokeCustomForm(data)
    } else {
      if (lent == 1) {
        return this.setState({ subcategory: data.attributes }, () => this.navigateCustomForm(data.attributes.sub_categories.data[0]))
      }
      this.setState({ subcategory: data.attributes, showModel: true })
    }
  }

  hideModal = () => this.setState({ showModel: false });

  goToHome = () => {
    this.props.navigation.navigate("LandingPage");
  }
  goToProfile = () => {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }
  goToHelpCenter() {
    this.props.navigation.navigate("HelpCenter");
  }
  goToSettingsFn() {
    const mssg: Message = new Message(getName(MessageEnum.NavigationMessage));
    mssg.addData(getName(MessageEnum.NavigationTargetMessage),
      "Settings2");
    mssg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(mssg);
  }
  goToQuoteFn = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    this.setCategoryTxt("bespoke");
    this.onBespokePackagesClick();
  }
  goToEnquiryFn = () => {
    const enq: Message = new Message(getName(MessageEnum.NavigationMessage));
    enq.addData(getName
      (MessageEnum.NavigationTargetMessage), "Enquiries");
    enq.addData(getName
      (MessageEnum.NavigationPropsMessage), this.props);
    this.send(enq);
  }

  isSessionResponseToken(message: Message): boolean {
    return getName(MessageEnum.SessionResponseToken) === message.id;
  }

  isRestAPIResponse(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  getApiRequestCallId(message: Message): string {
    return message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  }

  getResponseJson(message: Message): any {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  }

  getErrorResponse(message: Message): any {
    return message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  }

  hasErrors(responseJson: any, apiRequestCallId: string): boolean {
    return !!responseJson.errors && [
      this.getCategoriesApiCallId,
      this.getBespokeCategoriesApiCallId,
      this.getPreviousCategoriesApiCallId
    ].includes(apiRequestCallId);
  }

  handleApiResponse(apiRequestCallId: any, responseJson: any, errorResponse: any) {
    const handlers = {
      [this.getCategoriesApiCallId]: () => this.allPackagesFn(responseJson),
      [this.getBespokeCategoriesApiCallId]: () => this.yourPackagesFn(responseJson),
      [this.getPreviousCategoriesApiCallId]: () => this.setState({
        previousCategoryList: responseJson,
        currentPage: 1,
        isLoadingPrevious: false,
      }),
    };

    const handler = handlers[apiRequestCallId] || (() => {
      if (apiRequestCallId === this.getProfileAccountID) {
        const { attributes } = responseJson.data;
        this.setState({
          companyProfilePicture: attributes.company_logo,
        });
      } else if (apiRequestCallId === this.createInquiryId) {
        if (responseJson.inquiry) {
          this.props.navigation.navigate("CustomForm", {
            id: responseJson.inquiry.data.attributes.id,
          });
        }
      } else if (apiRequestCallId === this.getInquiryId) {
        this.handleViewEnquiry(responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorResponse);
      }
    });

    handler();
  }

  handleError = (responseJson: any) => {
    const errors: any[] = responseJson.errors;
    let allerrors = '';
    !!errors && errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        const err = JSON.parse(newLocal)
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${err.error}`;
            this.setState({ errorMessage: allerrors })
            this.setState({
              openDialogName: "Error 2",
              errorMessage: `${responseJson.message}. 
                            ${allerrors}.
                            Please refresh and try again and contact support if problem is persists` },)
          }
        }
      });
    }) }
  // Customizable Area End
}
