import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createProfile, profile } from "./assets";
import * as Yup from "yup";
import React from "react";
import { throttle } from "lodash";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface InitialValueType {
  first_name?: string,
  lastName?: string,
  email?:  string,
  full_phone_number?: string,
  phoneNumber?: number,
  jobTitle?: string,
  location?: string,
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface userPData {
        first_name: string,
          account_type: string,
          type: string,
          last_name: string,
          email: string,
          full_phone_number: number,
          company_name: string,
          location: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  account: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  updateProfileSchema:any;
  token: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  errorFailureMessage:string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  selectedFile: File |null;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;
  showModel: boolean,

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  showWaring:boolean;
  showError:boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  userProfileData: string;
  userProfileDatalastname :string;
  userProfileDataemail : string;
  updatedProfile: any;
  companyEmail:string;
  userProfileDatamobile : string;
  userPhoneNumber:string;
  userProfileDatalocation: string;
  userProfileDatacompany : string;
  userProfileDataJobTitle: string;
  userPrfileDataAccountType: string;
  userProfileDataAccountManager: string;
  isUserProfileUploaded: boolean;
  userProfileDatatype: string;
  userProfilePicture:string;
  isLoading:boolean;
  isSuspended : boolean ;
  suspendedMessage : string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  initialValue:InitialValueType = {};
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  updateProfileApiCallId: string = "";
  confrimProfileDetailsAPICallId : string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  userSuspendedApiCallId : string  =  "";
  fileInput: React.RefObject<any>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    // Customizable Area Start
    let updateProfileSchema = {
      first_name: Yup.string()
            .max(20, configJSON.maxFirstLastName).required(configJSON.fnameRequired),
      last_name: Yup.string()
            .max(20, configJSON.maxFirstLastName).required(configJSON.lnameRequired),
      email: Yup.string()
            .required(configJSON.emailRequired),
      phoneNumber: Yup.string().required(configJSON.phoneRequired),
      jobTitle: Yup.string()
            .required(configJSON.jobRequired),
      location: Yup.string()
      .required(configJSON.locationRequired),
    };
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      profile: {},
      updateProfileSchema:updateProfileSchema,
      account: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      companyEmail:"",
      address: "",
      selectedFile: null,
      postalCode: "",
      userProfilePicture:"",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      errorFailureMessage:"",
      user_name: "",
      dateOfBirth: "",
      token:'',
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      showModel:false,
      instagram: "",
      facebook: "",
      qrCode: "",
      isLoading:true,
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      showWaring:false,
      showError:false,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      userProfileData: '',
      userProfileDatalastname :'',
      userProfileDataJobTitle:'',
      userProfileDataemail:'',
      updatedProfile:[],
      userProfileDatamobile :'',
      userProfileDatalocation:'',
      userProfileDatacompany :'',
      userPrfileDataAccountType :'',
      userProfileDataAccountManager:"",
      isUserProfileUploaded: false,
      userProfileDatatype:'',
      userPhoneNumber:'',
      isSuspended : false,
      suspendedMessage : "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.fileInput = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
      this.fetchProfileData();
      window.addEventListener("mousemove", this.checkIsUserIsSuspended)
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
      if (apiRequestCallId === this.getProfileAccountID) {
        if (responseJson.data) {
          const { attributes } = responseJson.data;
          this.initialValue = {
            first_name: responseJson.data.attributes.first_name,
            lastName: responseJson.data.attributes.last_name,
            email:  responseJson.data.attributes.email,
            full_phone_number: responseJson.data.attributes.full_phone_number,
            phoneNumber: responseJson.data.attributes.phoneNumber,
            jobTitle: responseJson.data.attributes.jobTitle,
            location: responseJson.data.attributes.location,
          };
          this.setState({
            userProfilePicture: attributes.profile_picture,
            userProfileData: attributes.first_name,
            userProfileDatalastname: attributes.last_name,
            userProfileDataemail: attributes.email,
            userProfileDatamobile: attributes.full_phone_number,
            userPhoneNumber: attributes.phone_number.toString(),
            userProfileDatatype: attributes.type === "ClientAdmin" ? "Account Admin" : "Client User",
            userProfileDataJobTitle: attributes.job_title,
            userProfileDatalocation: attributes.location,
            userProfileDatacompany: attributes.company.name,
            userPrfileDataAccountType: attributes.account_type,
            userProfileDataAccountManager: attributes.company.account_manager_name,
            companyEmail: attributes.company.email,
            isLoading:false
          });
        }
      } else if (apiRequestCallId === this.updateProfileApiCallId) {
        if (responseJson.error===null) {
          this.confrimProfileDetails()
        } else if (responseJson.error) {
            this.setState({ showWaring: true }); 
        }
      } else if (apiRequestCallId === this.confrimProfileDetailsAPICallId) {
        if(responseJson.account){
          this.setState({ showWaring: false, showError: false, showModel: true,errorFailureMessage:"",userProfileDataemail: responseJson.account.data.attributes.email,
          companyEmail: responseJson.account.data.attributes.company.email},
          () => {
              this.fetchProfileData();
          });
          localStorage.removeItem("userName");
          localStorage.setItem("userName", responseJson.account.data.attributes.first_name);
        }else{
          this.setState({showError:true})
        }
      }
      this.receiveUserSuspended(message)
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  receiveUserSuspended = (message:Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if((apiRequestCallId === this.userSuspendedApiCallId)){
      this.handleIsUserSuspended(message)
    }
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  async componentWillUnmount() {
    window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
  }

  checkIsUserIsSuspended = throttle(async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      "token": await getStorageData("authToken")
    };
    this.userSuspendedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suspendedUserEndPoint 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  },4000)

  handleIsUserSuspended = (message:Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(responseJson.status && responseJson.status === "suspended"){
      window.removeEventListener("mousemove", this.checkIsUserIsSuspended)
      this.setState({isSuspended:true,suspendedMessage:responseJson.message})
    }
  }

  handleGoToLoginPage = async () => {
    await removeStorageData("authToken")
    await removeStorageData("role")
    this.setState({isSuspended:false,suspendedMessage:""})
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };
  formDataFormat = (dataVal: any) => {
    let data = Object.fromEntries(
        Object.entries(dataVal).filter(([key, v]) => v !== ''),
      )
    const formData:any = new FormData();
    data.first_name && formData.append(`account[first_name`,  data.first_name)
    data.last_name && formData.append(`account[last_name]`, data.last_name)
    data.email && formData.append(`account[email]`, data.email)
    data.full_phone_number && formData.append(`account[full_phone_number]`, data.full_phone_number)
    data.jobTitle && formData.append(`account[job_title]`, data.jobTitle)
    data.location && formData.append(`account[location]`, data.location)
    if(this.state.selectedFile){
      formData.append(`account[profile_picture]`, this.state.selectedFile)
    }
    return formData;
  }

  submitProfileDetails(dataVal:any) {
    let data = Object.fromEntries(
      Object.entries(dataVal).filter(([key, v]) => v !== '')
  );

  const diff = Object.keys(dataVal)
  .filter((itemKey) => dataVal[itemKey as keyof InitialValueType] !== this.initialValue[itemKey as keyof InitialValueType])
  .reduce((itemAcc, itemKey) => {
    itemAcc[itemKey as keyof InitialValueType] = dataVal[itemKey as keyof InitialValueType];
      return itemAcc;
  }, {} as Partial<InitialValueType>);

    let formDataUpdateProfile = this.formDataFormat(diff)
    this.setState({account:formDataUpdateProfile})
    const header = {
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/match_company_domain?email=${data.email}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    

  }
  handleChange = (event: any) => {
    const errors: { email: string } = { email: '' };
   
    const userProfileDataEmail = this.state.userProfileDataemail || "";
    const emailDomain = userProfileDataEmail.split("@")[1];
    const companyemail = this.state.companyEmail||"";
    const comapnyDomain = companyemail.split('@')[1];

    if (!event.target.value.includes(emailDomain)&&!event.target.value.includes(comapnyDomain)&&event.target.value.length>0) {
      errors.email = "You've entered an email address from an external domain. Please confirm this is correct before saving.";
    }
    this.setState({ errorFailureMessage: errors.email });
    return errors;
  
  };

  selectProfile = () => {
    let profileImageSrc;
  
    if (this.state.selectedFile) {
      profileImageSrc = URL.createObjectURL(this.state.selectedFile);
    }else if (this.state.userProfilePicture!="No File Attached") {
      profileImageSrc =this.state.userProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };

  selectVeiwProfile = () => {
    let profileImageSrc;
  
    if (this.state.userProfilePicture) {
      profileImageSrc =this.state.userProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };

  selectViewProfile = () => {
    let profileImageSrc;
  
    if (this.state.userProfilePicture!="No File Attached") {
      profileImageSrc =this.state.userProfilePicture;
    } else {
      profileImageSrc = createProfile; 
    }
    return profileImageSrc; 
  };
  

  confrimProfileDetails() {
    const header = {
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confrimProfileDetailsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAccountProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.state.account
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({showWaring:false})
    return true;

  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccountProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.account)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('authToken')
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getViewUserProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFileChange = (e: any) => {
    const fileInput = e.target;
    const selectedFile = fileInput.files && fileInput.files[0];

    if (selectedFile) {
      this.setState({ selectedFile, isUserProfileUploaded: true});
    }
  };

  openFileInput = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  displayModel = () => {
    this.setState(
      {
        showModel:true
      }
      )
  }

  hideModal = () => {
    this.setState({showModel: false});
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableuserProfiles");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  hideWarningModal = () => 
    this.setState(
      {
         showWaring: false
         }
      );

      hideErrorModal = () => 
      this.setState(
        {
           showError: false
           }
        );

  goToHome = () => {
    this.props.navigation.navigate("LandingPage");
  }
  goToProfile = () => {
    this.props.navigation.navigate("CustomisableuserProfiles");
  }
  goToEdit =() =>{
    this.props.navigation.navigate("EditProfile")
  }
  goToHelpCenterUser() {
    this.props.navigation.navigate("HelpCenter");
  }

  goToSettingsUser()
  {
    const msg: Message = 
    new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage),
     "Settings2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
    goToQuote = () =>
    {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 
    "Categoriessubcategories"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    }
    
    goToEnquiry = () =>{
    const msg: Message = new Message(getName(
      MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Enquiries"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props
    );
    this.send(msg
      );
    }
  // Customizable Area End
}
