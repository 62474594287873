Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.supsendedModalTitle = "Account Suspended";
exports.supspendedModalOkBtn = "OK"
exports.suspendedUserEndPoint = "/account_block/accounts/check_company_suspension"
exports.btnExampleTitle = "CLICK ME";
exports.home = "Home";
exports.request = "REQUEST";
exports.quote =  "A Quote";
exports.startNowBtn =  "Start Now";
exports.enquires = "Enquiries";
exports.viewBtn = "View";
exports.approvedEnquiries = "Approved Enquiries";
exports.getViewUserProfileEndPoint = "account_block/accounts/specific_account";
exports.getAllTeamMemberEndPoint = "account_block/accounts/company_users"
exports.getInvoicesEndPoint = '/get_invoices?filter_by=month'
exports.landingPage = "LondonFilmedEventBookingPlatform";
exports.getNotificationsEndPoint = 'bx_block_notifications/notifications/'
// Customizable Area End